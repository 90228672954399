<template>
  <div>
    <a-card style="margin-top: 24px;" :bordered="false">
      <!-- 主贴内容 -->
      <div class="forum-main">
        <div class="forum-main-header">
          <a-avatar class="forum-avatar spacing" size="large"
            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
          <div style="float: left;">
            <h4 class="forum-title">{{ info.title }}</h4>
            <div>
              <a class="spacing" style="font-size: 11px;" href="#">{{ info.nick_name }}</a>
              <span class="forum-time">{{ info.create_time | dayjs }}</span>
            </div>
          </div>
        </div>
        <div style="clear:both"></div>
        <div class="forum-content">
          <p v-html="info.content"></p>
        </div>
      </div>
      <a-list size="large" :pagination="pagination" rowKey="id" :loading="loading" itemLayout="vertical"
        :dataSource="data">
        <a-list-item :key="item.id" slot="renderItem" slot-scope="item">
          <div>
            <div class="forum-content">
              <p v-html="'<span># ' + item.id + '：</span>' + item.content"></p>
            </div>
            <a-avatar :size="20" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
            <a class="spacing" style="font-size: 11px;" href="#">{{ item.nick_name }}</a>
            <span class="forum-time spacing">{{ item.create_time }}</span>
            <a v-if="item.reply_count > 0" @click="showReplyHandle(item.id)" type="link">{{ showReply[item.id] ? '回复(' +
              item.reply_count + ')' : '收起回复'
            }}</a>
            <!-- 评论，先判断是否存在评论 -->
            <a-row v-if="item.reply_count" v-show="showReply[item.id] ? false : true">
              <a-col :span="22" :offset="2">
                <a-list size="large" :pagination="reflex_pagination" :rowKey="id" :loading="loading" itemLayout="vertical"
                  :dataSource="item.reflex_data">
                  <a-list-item :key="reflex_item.id" slot="renderItem" slot-scope="reflex_item">
                    <div style="background: #f7f8fa;padding: 10px">
                      <a-avatar :size="20" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                      <a class="spacing" style="font-size: 11px;" href="#">{{ reflex_item.nick_name }}</a>:
                      <span>{{ reflex_item.content }}</span>
                      <div style="text-align: right;">
                        <span class="reply-time spacing">{{ reflex_item.create_time | dayjs }}</span>
                      </div>
                    </div>
                  </a-list-item>
                </a-list>
              </a-col>
            </a-row>
          </div>
        </a-list-item>
      </a-list>
      <!-- <div>
        <a-space :size="20">
          <a-button type="primary">置顶/取消置顶</a-button>
          <a-button type="primary">删除</a-button>
          <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
        </a-space>
      </div> -->
    </a-card>
  </div>
</template>

<script>
import { forumInfo } from '@/api/forum'
import { TagSelect, StandardFormRow, ArticleListContent } from '@/components'
import IconText from './components/IconText'
const TagSelectOption = TagSelect.Option

export default {
  components: {
    TagSelect,
    TagSelectOption,
    StandardFormRow,
    ArticleListContent,
    IconText
  },
  data() {
    return {
      id: '',
      loading: true,
      loadingMore: false,
      info: {},
      data: [],
      form: this.$form.createForm(this),
      tempShowReply: [],
      pagination: {},
      reflex_pagination: {},
      showReply: []
    }
  },
  created() {
    const id = parseInt(this.$route.query.id)
    console.log(id)
    if (id !== undefined) {
      this.id = id
    } else {
      this.$router.back(-1)
    }
  },
  mounted() {
    //   this.getList()
    this.fetch({ id: this.id })
  },
  watch: {
    tempShowReply(val) {
      this.showReply = Object.assign({}, val)
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      // 带上查询参数
      const queryParam = this.form.getFieldsValue()
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...queryParam
      })
    },
    fetch(params = {}) {
      this.loading = true
      new Promise((resolve, reject) => {
        forumInfo(params).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        console.log(data)
        //   const pagination = { ...this.pagination }
        this.info = data.info
        this.data = data.reply_data
        //   pagination.total = data.list.total
        //   pagination.pageSize = data.list.per_page
        //   pagination.current = data.list.current_page
        //   this.pagination = pagination
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
        this.$router.back(-1)
      }).finally(() => {
        this.loading = false
      })
    },
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    getList() {
      this.$http.get('/list/article').then(res => {
        console.log('res', res)
        this.data = res.result
        this.loading = false
      })
    },
    loadMore() {
      this.loadingMore = true
      this.$http.get('/list/article').then(res => {
        this.data = this.data.concat(res.result)
        console.log(this.data)
      }).finally(() => {
        this.loadingMore = false
      })
    },
    routerback: function () {
      this.$router.back(-1)
    },
    showReplyHandle(e) {
      if (this.tempShowReply[e] === undefined) {
        this.$set(this.tempShowReply, e, true)
      } else {
        const status = !this.showReply[e]
        this.$set(this.tempShowReply, e, status)
      }
    },
    setOwner() {
      const { form: { setFieldsValue } } = this
      setFieldsValue({
        owner: ['wzj']
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-components-tag-select {
  /deep/ .ant-pro-tag-select .ant-tag {
    margin-right: 24px;
    padding: 0 8px;
    font-size: 14px;
  }
}

.list-articles-trigger {
  margin-left: 12px;
}

.forum-main {
  border-bottom: 1px solid #e8e8e8;
  padding-top: 16px;
  padding-bottom: 16px;
}

.forum-main-header {
  height: 40px;
}

.spacing {
  margin-right: 10px;
}

.forum-avatar {
  float: left;
}

.forum-content {
  margin: 10px 0px;
}

.forum-title {
  margin-top: 6px;
  margin-bottom: 0px;
  color: rgba(0, 0, 0, .85);
  font-weight: 600;
  font-size: 14px;
}

.forum-time {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.25);
  font-style: normal;
}

.reply-time {
  margin: 0;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.25);
  font-style: normal;
}
</style>
