import api from './index'
import { axios } from '@/utils/request'

// 获取论坛帖子列表
export function forumList(parameter) {
    return axios({
        url: api.ForumList,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 帖子详情
export function forumInfo(parameter) {
    return axios({
        url: api.ForumInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 创建管理员帖子
export function forumCreate(parameter) {
    return axios({
        url: api.ForumCreate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改管理员帖子
export function forumSave(parameter) {
    return axios({
        url: api.ForumSave,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除管理员帖子
export function forumDelete(parameter) {
    return axios({
        url: api.ForumDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
